
import axios from "axios";
import qs from "qs";
import router from "./index.js";


axios.defaults.baseURL = router.baseURL;
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = false;

/**
 * 设置请求传递数据的格式（看服务器要求的格式）
 * x-www-form-urlencoded
 */
// axios.defaults.withCredentials=true
axios.defaults.crossDomain=true
  axios.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.transformRequest = (data) => qs.stringify(data)

  

/**
 * 请求拦截器
 */
axios.interceptors.request.use(
  (config) => {
    
    let token = sessionStorage.getItem("token");
   
    token && (config.headers.token = token);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  (response) => {
    // return response.data;
    // console.log(response.data);return false
    if (response.data.code == '401') {
      sessionStorage.removeItem("token");
      location.href = '#/login'
    } else {
      return response.data;
    }
  },
  (error) => {}
);

export default axios;

export const getRequest = (url, params) => {
  return axios({
    method: "get",
    url: url,
    params: params,
  });
};

export const postRequest = (url, params,config={}) => {
  return axios({
    method: "post",
    headers:config,
    url: url,
    data: params,
  
  });
};

